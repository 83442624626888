<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Categoria de Carta - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                
                <b-col md="3">
                  <div class="w-100 text-center">
                     <img class="img-fluid" :src="previewImage">
                  </div>
                  <b-col md="12">
                    <b-form-group label="Foto: ">
                      <b-form-file @change="PreviewImage" accept="image/*" v-model="photo" placeholder="Seleccione un foto..." drop-placeholder="Suelta la imagen aquí..."></b-form-file>
                      <small class="form-text text-center text-secondary">Tamaño recomendado 400px x 500px</small>
                    </b-form-group>
                  </b-col>
                </b-col>

                <b-col md="9">
                  <b-row>
                      <b-col md="12">
                        <b-form-group label="Nombre:">
                          <b-form-input type="text" v-model="category_letter.name" placeholder="Ingrese un nombre"></b-form-input>
                          <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                        </b-form-group>
                      </b-col>
              
                      <b-col md="12">
                        <b-form-group label="Descripción :">
                          <b-form-input type="text" placeholder="Ingrese una descripción" v-model="category_letter.description"></b-form-input>
                        </b-form-group>
                      </b-col>


                      <b-col md="3">
                        <b-form-group label="IP de Impresora:">
                          <b-form-input v-model="category_letter.printer_ip"></b-form-input>
                          <small v-if="errors.printer_ip" class="form-text text-danger">Ingrese una ip</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="IP de Impresora Sec.:">
                          <b-form-input v-model="category_letter.printer_id_secondary"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="IP de Impresora Terc.:">
                          <b-form-input v-model="category_letter.printer_id_third"></b-form-input>
                        </b-form-group>
                      </b-col>
                      
                      <b-col md="3">
                        <b-form-group label="Estado :">
                          <b-form-select v-model="category_letter.state" :options="state"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="5"></b-col>
                      <b-col md="2">
                        <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                      </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

     <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CategoryLetter',
      category_letter: {
        name: "",
        description: "",
        photo: "",
        state: 1,

        printer_ip: "",
        printer_id_secondary:"",
        printer_id_third:"",
        printer_name: "",
      },
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      previewImage: 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg',
      photo: null,
      errors: {
        name: false,
        photo:false,
        printer_ip:false,
        printer_name:false,
      },
      validate: false,
    };
  },
  mounted() {
  
  },
  methods: {
    AddCategoryProduct,
    Validate,
    PreviewImage,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function PreviewImage(event) {
  var input = event.target;
  if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
          this.previewImage = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
  }
  this.category_letter.photo = input.files[0];
}

function AddCategoryProduct() {
  let me = this;
  let url = me.url_base + "category-letter/add";
  let data = new FormData();
  data.append("name", me.category_letter.name);
  data.append("description", me.category_letter.description);
  data.append("photo", me.category_letter.photo);
  data.append("state", me.category_letter.state);
  data.append("printer_ip", me.category_letter.printer_ip);
  data.append("printer_id_secondary", me.category_letter.printer_id_secondary);
  data.append("printer_id_third", me.category_letter.printer_id_third);
  data.append("printer_name", me.category_letter.printer_name);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 2},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.category_letter.name = "";
        me.category_letter.description = "";
        me.category_letter.state = 1;
        me.category_letter.printer_ip = "";
        me.category_letter.printer_id_secondary = "";
        me.category_letter.printer_id_third = "";
        me.category_letter.printer_name = ""
        me.category_letter.photo = 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg';
        me.photo = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {
  this.errors.name = this.category_letter.name.length == 0 ? true : false;
  this.errors.state = this.category_letter.state.length == 0 ? true : false;
  this.errors.printer_ip = this.category_letter.printer_ip.length == 0 ? true : false;
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.printer_ip) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

   if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar la categoria ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddCategoryProduct(me);
      }
    });

  }
}
</script>
